/* *{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #d5fefd;
  background-image: linear-gradient(315deg, #d5fefd 0%, #fffcff 74%);

}


*/

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=PT+Sans:wght@700&display=swap');

body {
  font-family: Arial, sans-serif;
  background: url('./bg6.jpg');
  font-family: 'Lobster', cursive;
  background-repeat: no-repeat;
  font-family: 'PT Sans', sans-serif;
  /* background-color: #d5fefd;
  background-image: linear-gradient(315deg, #d5fefd 0%, #fffcff 74%); */
  margin: 0;
  padding: 0;
  background-position: center center;
  background-size: cover;

}

br{
  display: none;
}

section{
  min-height: 100vh;
  overflow-y: hidden;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.img_container {
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  position: relative;
  z-index: 1;
}

.img_container img{
  width: 250px;
}

a{
  text-decoration: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
}

h1 {
  font-size: 5.5rem;
  color: #333;
  margin-bottom:2.5rem;
  font-family: 'Lobster', cursive;
  line-height: 1;

}

p {
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 1.8rem;
  font-style: italic;
}

.btn_container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_container button{
  border: 3px solid #ffb800;
  margin:2rem 0.5rem 0;
  padding: 10px 26px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 1.2px;
}

.btn_container .button1{
  background: #ffb800;
}


.btn_container .button2{
  background: #fff;
}


.btn_container button span{
  display: inline-block;
}
.btn_container button img{
  display: inline-block;
  margin-left: 0.8rem;
  width: 2rem;

}



@media (max-width: 800px) {
  p{
    font-size: 1.5rem;
  }
  
  h1{
    font-size: 4.5rem;
  }
  br{
    display: block;
  }

  .btn_container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .btn_container button{
    margin-top: 1rem;
  }
  }


  @media (max-width: 410px) {
    section{
      max-width: 92%;
    }

    h1{
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    br{
      display: block;
    }

    p{
      font-size: 1.25rem;
    }

    .btn_container button{
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }